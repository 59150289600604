// colors

.bg-gradient-theme {
  background: linear-gradient(to bottom, '#109CF1', '#109CF1');
}

.bg-gradient-theme-top {
  background: linear-gradient(to top, theme-color("primary"), theme-color("secondary"));
}

.bg-gradient-theme-left {
  background: linear-gradient(to left, theme-color("primary"), theme-color("secondary"));
}

.bg-gradient-theme-right {
  background: linear-gradient(to right, theme-color("primary"), theme-color("secondary"));
}

.bg-blue {
  background: transparent !important;
  background-color: $blue !important;

  &:hover {
    background: transparent !important;
    background-color: $blue !important;
    opacity: 0.9 !important;
  }

  &:focus {
    box-shadow: 0 0 0 0 !important;
  }
}
