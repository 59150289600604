@import './layout.scss';
@import './custom.scss';

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #f5f6f8 !important;
}
