.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(16, 156, 241, 0.15);
      opacity: 1;

      > svg {
        fill: #109cf1 !important;
      }

      > span {
        color: #109cf1 !important;
        font-weight: 500;
      }
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  // text-transform: uppercase;
  font-weight: 500;
}

.badge:empty {
  display: inline-block;
}

.shadow-c {
  box-shadow: 0 0 4px 0 rgba(136, 136, 136, 0.6);
  transition: 0.3s;
}

.DateRangePickerInput {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}

.DateInput {
  height: 36px !important;
  width: 40% !important;

  > input {
    height: 36px !important;
    font-weight: 400;
  }
}
