
.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #109cf1 !important; //#e2f5ff
  border: 1px solid #eaeaea !important;
  color: #fff !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #1191e0 !important;
  border: 1px solid #fcfcfc !important;
  color: #fff !important;
}

.DateInput_input__focused {
  border-bottom-color: #109cf1 !important;
}

.DateInput__small {
  //  width: 118px !important;
}

.DateRangePickerInput {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}

.DateInput {
  height: 36px !important;

  > input {
    height: 36px !important;
    font-weight: 400;
  }
}

.w-35 {
  width: 35% !important;
}

.rt-resizable-header-content {
  text-align: center !important;
}

.rt-td {
  text-align: right !important;

  > span {
    text-align: right !important;
  }
}
