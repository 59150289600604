.cr-header {
  margin-bottom: 15px;
  border-bottom: 2px solid #ebeff2;
  height: 60px;

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }

  .mr-2 {
    > button {
      background-color: #fff;
      box-shadow: 0 0 0 0.1rem #109cf1 !important;
      border: 0 !important;
      color: #109cf1;

      &:focus {
        box-shadow: 0 0 0 0.1rem #109cf1 !important;
        border: 0;
      }

      &:active {
        background-color: rgba(16, 156, 241, 0.15) !important;
        border: 0 solid #109cf1 !important;
        box-shadow: 0 0 0 0.1rem #109cf1 !important;
        color: #109cf1 !important;
      }
    }
  }

  // &__notification-popover {
  //   &.popover {
  //     width: 320px;
  //     max-width: 320px;
  //     max-height: 100vh;
  //     overflow-y: scroll;
  //     cursor: pointer;
  //   }
  // }
}

.logoStyle{
  height:50px;
}
